import React from 'react'
import { Route, Routes } from 'react-router-dom'

// * modules
import { default as Scripts } from '../modules/Scripts'

function App() {
	return <Routes>
		<Route path="scripts/*" element={<Scripts />} />
	</Routes>
}

export default App
