import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { Col, Row, Select } from 'components/Ui'
import http from 'axios'
import { Context } from './context'

// * modules
import Cha from '@server/cha/app'
import Bm from '@server/bm/app'
import Ecom from '@server/ecom/app'
import Logs from '@server/logs/app'

let serverInterceptor = false
function Server() {
	const location = useLocation()
	const navigate = useNavigate()

	const [selectedServer, setSelectedServer] = useState('')
	const [servers, setServers] = useState([])

	// Se non ho un server selezionato e sono in una pagina di edit ritorno alla home
	if (location.pathname.split('/').length > 4 && selectedServer == '') {
		navigate('/', { replace: true })
	}

	// Quando cambio il server selezionato ritorno alla pagina della lista
	useEffect(() => {
		navigate(location.pathname.split('/').slice(0, 4).join('/'), { replace: true })
	}, [selectedServer])

	const loadServers = async() => {
		const servers = await http.get(`${window.__endpoint}/server/cha/servers/list?cols=server_id,name&order=val_order`)
		setServers(servers.data)
	}

	useEffect(() => {
		loadServers()

		return () => {
			// On component unmount
			if (serverInterceptor) {
				http.interceptors.request.eject(serverInterceptor)
			}
		}
	}, [])

	const handleServerChange = (e) => {
		const serverId = e.target.value
		setSelectedServer(serverId)

		// Rimuovo l'eventuale parametro managed_server_id già impostato
		if (serverInterceptor) {
			http.interceptors.request.eject(serverInterceptor)
		}

		// Imposto il parametro managed_server_id con il server selezionato
		if (serverId > 0) {
			serverInterceptor = http.interceptors.request.use(
				(request) => {
					request.params = { ...request.params, managed_server_id: serverId }
					return request
				}
			)
		}
	}

	return <React.Fragment>
		<Row>
			<Col all={6}>
				<Select
					className="w-full"
					value={selectedServer}
					onChange={handleServerChange}
					options={servers || []}
					optionValue={(o) => o.server_id}
					optionLabel={(o) => o.name}
					all={{
						label: 'Seleziona un server'
					}}
				/>
			</Col>
		</Row>
		{selectedServer != '' && <Context.Provider value={{ selectedServer }}>
			<Routes>
				<Route path="/cha/*" element={<Cha />} />
				<Route path="/bm/*" element={<Bm />} />
				<Route path="/ecom/*" element={<Ecom />} />
				<Route path="/logs/*" element={<Logs />} />
			</Routes>
		</Context.Provider>}
	</React.Fragment>
}

export default Server
