import React, { useEffect, useState } from 'react'
import { Col, Checkbox, FormGroup, InputText, InputTextarea, Row, Select, HTMLEditor } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'
import { Divider } from 'primereact/divider'

function Edit({ moduleId, editItem, setEditItem, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const file_measures = await http.get(`${window.__endpoint}/cha/list_lines/get/list?cols=list_value_code,list_value_id,name&list_id=58`)

		setSelectOptions({
			file_measures: file_measures.data,
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name', 'max_size','max_size_um_id']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Nome */}
					<Col all={12}>
						<FormGroup
							name="name"
							label="Nome"
							validationState={validator.validationState.name}
						>
							<InputText
								value={editItem.name || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Estensioni */}
					<Col all={6}>
						<FormGroup
							name="extensions"
							label="Estensioni ammesse"
							validationState={validator.validationState.extensions}
						>
							<InputText
								value={editItem.extensions || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Dimensione massima file */}
					<Col all={4}>
						<FormGroup
							name="max_size"
							label="Dimensione massima file"
							validationState={validator.validationState.max_size}
						>
							<InputText
								type="number"
								value={editItem.max_size || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Unità di misura */}
					<Col all={2}>
						<FormGroup
							name="max_size_um_id"
							label="Unità di misura"
							validationState={validator.validationState.max_size_um_id}
						>
							<Select
								value={editItem.max_size_um_id || ''}
								onChange={onChange}
								options={selectOptions.file_measures || []}
								optionValue={(o) => o.list_value_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Ridimensiona */}
					<Col all={6}>
						<FormGroup
							name="resize_flag"
							label="Ridimensiona"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.resize_flag == 1}
							/>
						</FormGroup>
					</Col>
					{/* Mantieni le proporzioni */}
					{!!Number(editItem.resize_flag) && <Col all={6}>
						<FormGroup
							name="keep_ratio_flag"
							label="Mantieni le proporzioni"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.keep_ratio_flag == 1}
							/>
						</FormGroup>
					</Col>}
				</Row>
				{!!Number(editItem.resize_flag) && <React.Fragment>
					<Row>
						<Divider />
					</Row>
					<Row>
						{/* label superiori */}
						<Col all={6}>
							<h6>Immagine orizzontale</h6>
						</Col>
						<Col all={6}>
							<h6>Immagine verticale</h6>
						</Col>
						{/* Larghezza */}
						<Col all={3}>
							<FormGroup
								name="h_width"
								label="Larghezza"
								validationState={validator.validationState.h_width}
							>
								<InputText
									type="number"
									value={editItem.h_width || ''}
									onChange={onChange}
								/>
							</FormGroup>
						</Col>
						{/* Altezza */}
						<Col all={3}>
							<FormGroup
								name="h_height"
								label="Altezza"
								validationState={validator.validationState.h_height}
							>
								<InputText
									type="number"
									value={editItem.h_height || ''}
									onChange={onChange}
								/>
							</FormGroup>
						</Col>
						{/* Larghezza */}
						<Col all={3}>
							<FormGroup
								name="v_width"
								label="Larghezza"
								validationState={validator.validationState.v_width}
							>
								<InputText
									type="number"
									value={editItem.v_width || ''}
									onChange={onChange}
								/>
							</FormGroup>
						</Col>
						{/* Altezza */}
						<Col all={3}>
							<FormGroup
								name="v_height"
								label="Altezza"
								validationState={validator.validationState.v_height}
							>
								<InputText
									type="number"
									value={editItem.v_height || ''}
									onChange={onChange}
								/>
							</FormGroup>
						</Col>
					</Row>
				</React.Fragment>}

				<Row>
					<Divider />
				</Row>
				<Row>
					{/* label superiori */}
					<Col all={12}>
						<h6>Ritaglio immagine</h6>
						<p>
							Dimensioni alle quali l'immagine dev'essere ritagliata dall'utente tramite interfaccia<br/>
							(da utilizzare solo quando il tipo immagine viene utilizzato per il ritaglio)
						</p>
					</Col>
					{/* Larghezza */}
					<Col all={6}>
						<FormGroup
							name="crop_width"
							label="Larghezza"
							validationState={validator.validationState.crop_width}
						>
							<InputText
								type="number"
								value={editItem.crop_width || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Altezza */}
					<Col all={6}>
						<FormGroup
							name="crop_height"
							label="Altezza"
							validationState={validator.validationState.crop_height}
						>
							<InputText
								type="number"
								value={editItem.crop_height || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
