import React from 'react'

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(
	BarElement,
	CategoryScale,
	LinearScale,
	Title,
	Tooltip,
	Legend
)

export default function Graphs({ data }) {
	// Elaboro i dati per il grafico

	// Genero l'elenco degli orari
	const dataset = {}
	for (let i = 0; i < 24; i++) {
		for (let j = 0; j < 60; j += 5) {
			dataset[`${`0${i}`.slice(-2)}:${`0${j}`.slice(-2)}`] = 0
		}
	}

	// Estraggo il numero di esecuzioni a intervalli di 5 minuti
	data.forEach((item) => {
		const start = new Date(item.end)
		let hours = start.getHours()
		let minutes = Math.floor(start.getMinutes() / 5) * 5

		if (hours < 10) hours = `0${hours}`
		if (minutes < 10) minutes = `0${minutes}`

		const key = `${hours}:${minutes}`
		dataset[key]++
	})

	return <div>
		<Bar
			width={100}
			height={300}
			data={{
				labels: Object.keys(dataset),
				datasets: [
					{
						label:'Numero di chiamate',
						data: Object.values(dataset),
						borderWidth: 1,
						borderColor:'#ad2d2d',
						backgroundColor:'#ad2d2d',
						hoverBorderColor:'#701717',
						hoverBackgroundColor:'#701717'
					},
				]
			}}
			options={{
				maintainAspectRatio: false,
				elements: {
					rectangle: {
						borderWidth: 1
					}
				},
				responsive: true,
				legend: {
					'position':'bottom'
				},
				title: {
					text: '',
					'display': false,
					'position':'top'
				},
			}}

		/>
	</div>
}
