import React from 'react'
import { Button, Col, Row } from 'components/Ui'

function Filters({ filters, setFilters }) {
	// !Un filtro esclude l'altro, valutare se si aggiungono altri filtri se migliorarne la gestione
	const handleChangeFilterHour = (e) => {
		setFilters({
			...filters,
			fromHours: null,
			toHours: null,
			[e.target.name]: e.target.value,
		})
	}
	const handleChangeFilterRange = (e) => {
		setFilters({
			...filters,
			hour: null,
			[e.target.name]: e.target.value,
		})
	}

	return <Row>
		<Col all={6}>
			<label>Log processi in esecuzione ad un orario specifico</label>
			<Row>
				<Col all={12}>
					<input
						type="time"
						name="hour"
						value={filters.hour || ''} onChange={handleChangeFilterHour}
					/>
				</Col>
			</Row>
		</Col>
		<Col all={6}>
			<label>Log processi in esecuzione in un intervallo di orario</label>
			<Row>
				<Col all={6}>
					dalle: <input
						type="time"
						name="fromHours"
						value={filters.fromHours || ''}
						onChange={handleChangeFilterRange}
					/>
				</Col>
				<Col all={6}>
					alle: <input
						type="time"
						name="toHours"
						value={filters.toHours || ''}
						onChange={handleChangeFilterRange}
					/>
				</Col>
			</Row>
		</Col>
		<Col all={12}>
			<Button onClick={() => setFilters({})}>Reset</Button>
		</Col>
	</Row>
}

export default Filters
