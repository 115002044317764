import React from 'react'
import { Route, Routes } from 'react-router-dom'

// * modules
import { default as Logs } from '../modules/Logs'

function App() {
	return <Routes>
		<Route path="view/*" element={<Logs />} />
	</Routes>
}

export default App
