import React, { useEffect, useState } from 'react'
import moment from 'moment'

import Graphs from './Graphs/Graphs'
import DetailsTable from './DetailsTable/DetailsTable'
import Filters from './Filters/Filters'

function View({ logs }) {
	const [filters, setFilters] = useState({})
	const [myFilteredJson, setMyFilteredJson] = useState([])

	useEffect(() => {
		// Se cambia il log, resetto i filtri
		setFilters({})
	}, [logs])

	useEffect(() => {
		if (logs) {
			let filteredJson = logs
			if (filters.hour) {
				const date = logs[0].start.split(' ')[0]
				const datetime = moment(`${date} ${filters.hour}`).startOf('minute')

				filteredJson = filteredJson.filter((item) => {
					// Get the start and end date rounded to minutes
					const start = moment(item.start).startOf('minute')
					const end = moment(item.end).startOf('minute')
					return datetime.isSameOrAfter(start) && datetime.isSameOrBefore(end)
				})
			}

			if (filters.fromHours || filters.toHours) {
				// Ricavo la data del log
				const date = logs[0].start.split(' ')[0]

				// Creo un oggetto moment con la data e l'orario di inizio
				const fromDateTime = moment(`${date} ${filters.fromHours || '00:00'}`).startOf('minute')
				const toDateTime = moment(`${date} ${filters.toHours || '24:00'}`).startOf('minute')

				filteredJson = filteredJson.filter((item) => {
					// Get the start and end date rounded to minutes
					const start = moment(item.start).startOf('minute')
					const end = moment(item.end).startOf('minute')
					return fromDateTime.isSameOrBefore(end) && toDateTime.isSameOrAfter(end)
				})
			}

			setMyFilteredJson(filteredJson)
		}
	}, [logs, filters])

	return <div>
		<h4 style={{ textAlign: 'center' }}>Log del {moment(logs[0].end).format('DD/MM/YYYY')}</h4>
		<Graphs
			data={logs}
		/>

		<hr/>
		<Filters
			filters={filters}
			setFilters={setFilters}
		/>

		<hr />
		<p style={{ textAlign: 'center' }}><small>{myFilteredJson.length} di {logs.length}</small></p>
		<DetailsTable
			data={myFilteredJson}
		/>
	</div>
}

export default View
