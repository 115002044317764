import React, { useState } from 'react'
import { FiltersSidebar, ListToolbar, ListGrid } from 'components/List'

function List({ defaultFiltersValues, items, onDelete, onList, filtersValues, ...props }) {
	const [filtersVisible, setFiltersVisible] = useState(false)

	const executeScript = (id) => {
		const selected = items.find(item => item.script_id === id)
		const scripts = selected.scripts.split(';')
		if (confirm(`Procedere con il rilascio di ${selected.name} ?
${scripts.length > 1 ? `Verranno eseguiti i seguenti ${scripts.length} script:` : 'Verrà eseguito il seguente script:'}

${scripts.join('\n\n')}`)) {
			const token = localStorage.getItem('token')
			window.open(`${window.__endpoint}/deploy/code/scripts/execute?s[t]=${token}&id=${id}`)
		}
	}

	return <React.Fragment>
		<FiltersSidebar
			visible={filtersVisible}
			onHide={() => setFiltersVisible(false)}
			onSubmit={filters => onList(filters)}
			defaultValues={defaultFiltersValues}
			filters={[]}
		/>

		<ListToolbar
			onShowFilters={() => {
				setFiltersVisible(true)
			}}
			onReload={() => {
				onList(filtersValues)
			}}
			hideBtnAdd
		/>

		<ListGrid
			{...props}
			onDelete={onDelete}
			items={items}
			fieldId='script_id'
			columns={[
				{ field: 'script_id', header: 'Id', sortable: true },
				{ field: 'name', header: 'Nome', sortable: true },
			]}
			defaultSortField='name'
			defaultSortOrder={1}
			hideBtnEdit
			customItems={[
				{
					onClick: executeScript,
					label: 'Esegui',
					icon: 'fas fa-execute'
				}
			]}
		/>
	</React.Fragment>
}

export default List
