import React, { useEffect, useState } from 'react'
import { Routes, Route } from 'react-router-dom'

import Db from '@deploy/db/Db'
import Code from '@deploy/code/app'
import Cha from './cha/app'

function Deploy() {
	return <div>
		<Routes>
			<Route path="/db/*" element={<Db />} />
			<Route path="/cha/*" element={<Cha />} />
			<Route path="/code/*" element={<Code />} />
		</Routes>
	</div>
}

export default Deploy
