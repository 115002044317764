import React, { useState } from 'react'
import { Button, Modal } from 'components/Ui'
import http from 'axios'

function LogSelector({ onSelect }) {
	const [modalOpen, setModalOpen] = useState(false)
	const [logs, setLogs] = useState([])

	const handleListLogs = async() => {
		// Resetto il log attuale
		onSelect([])
		// Recupero la lista dei log disponibili
		const logs = await http.get(`${window.__endpoint}/server/logs/logs/list`)
		setLogs(logs.data)
		setModalOpen(true)
	}

	const handleSelectLog = async(selectedLog) => {
		setModalOpen(false)

		const log = await http.get(`${window.__endpoint}/server/logs/logs/load?log=${selectedLog}`)
		// Rimuovo l'ultima virgola
		const lastComma = log.data.lastIndexOf(',')
		const textWithoutComma = log.data.substring(0, lastComma)

		// Converto il contenuto del file in un array di Json
		onSelect(JSON.parse(`[${textWithoutComma}]`))
	}

	return <div>
		<Button onClick={handleListLogs}>Seleziona il log da visualizzare</Button>

		<Modal
			open={modalOpen}
			onClose={() => {
				setModalOpen(false)
			}}
			label='Elenco dei log disponibili'
		>
			{logs.map((log, l) => <p key={l}><Button onClick={() => handleSelectLog(log)}>{log}</Button></p>)}
		</Modal>
	</div>
}

export default LogSelector
