import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

// Edit module
import { LogSelector, View } from '../../Components'

function PageView() {
	const [myLogs, setMyLogs] = useState([])

	return <Card title="Logs">
		<LogSelector
			onSelect={(data) => setMyLogs(data)}
		/>

		{myLogs.length > 0 && <View
			logs={myLogs}
		/>}
	</Card>
}

export default PageView
