import React, { useEffect, useState } from 'react'
import { Col, FormGroup, InputText, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ moduleId, editItem, setEditItem, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const file_measures = await http.get(`${window.__endpoint}/cha/list_lines/get/list?cols=list_value_code,list_value_id,name&list_id=58`)
		const referementTypes = await http.get(`${window.__endpoint}/cha/referement_types/list?cols=refer_type_id,referment_full_name&order=referment_full_name`)

		setSelectOptions({
			file_measures: file_measures.data,
			generators: referementTypes.data
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name', 'max_size', 'max_size']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Nome */}
					<Col all={6}>
						<FormGroup
							name="name"
							label="Nome"
							validationState={validator.validationState.name}
						>
							<InputText
								value={editItem.name || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Descrizione */}
					<Col all={6}>
						<FormGroup
							name="description"
							label="Descrizione"
							validationState={validator.validationState.description}
						>
							<InputText
								value={editItem.description || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Estensioni */}
					<Col all={6}>
						<FormGroup
							name="extensions"
							label="Estensioni"
							validationState={validator.validationState.extensions}
						>
							<InputText
								value={editItem.extensions || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Dimensione massima file */}
					<Col all={2}>
						<FormGroup
							name="max_size"
							label="Dimensione massima file"
							validationState={validator.validationState.max_size}
						>
							<InputText
								type="number"
								value={editItem.max_size || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Unità di misura */}
					<Col all={2}>
						<FormGroup
							name="max_size_um_id"
							label="Unità di misura"
						>
							<Select
								value={editItem.max_size_um_id || ''}
								onChange={onChange}
								options={selectOptions.file_measures || []}
								optionValue={(o) => o.list_value_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
					{/* Generatore */}
					<Col all={2}>
						<FormGroup
							name="generator_refer_type_id"
							label="Generatore"
						>
							<Select
								value={editItem.generator_refer_type_id || ''}
								onChange={onChange}
								options={selectOptions.generators || []}
								optionValue={(o) => o.refer_type_id}
								optionLabel={(o) => o.referment_full_name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
