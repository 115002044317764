import React, { useEffect } from 'react'
import moment  from 'moment'
import { JsonView, collapseAllNested, darkStyles } from 'react-json-view-lite'
import 'react-json-view-lite/dist/index.css'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

export default function DetailsTable({ data }) {
	const [jsonLine, setJsonLine] = React.useState(null)

	useEffect(() => {
		setJsonLine(null)
	}, [data])

	if (data.length > 3000) {
		return <div>Troppi record selezionati, impostare un filtro per visualizzare il dettaglio</div>
	}

	return <DataTable
		size='small'
		value={data}
	>
		<Column
			style={{ width: '5%' }}
			field="start"
			body={(item) => moment(item.start).format('HH:mm:ss')}
			header="Start"
		/>
		<Column
			style={{ width: '5%' }}
			field="start"
			body={(item) => moment(item.end).format('HH:mm:ss')}
			header="End"
		/>
		<Column
			style={{ width: '15%' }}
			field="execution_time"
			header="Execution (sec.)"
		/>
		<Column
			style={{ width: '15%' }}
			field="refer"
			header="Refer"
		/>
		<Column
			field="params"
			body={(item, options) => <React.Fragment>
				{jsonLine != options.rowIndex && <div style={{ cursor: 'pointer', maxWidth: '100%', overflowWrap: 'anywhere' }} onClick={() => setJsonLine(options.rowIndex)}>
					{JSON.stringify(item.params)}
				</div>}
				{jsonLine == options.rowIndex && <JsonView data={item.params} shouldExpandNode={collapseAllNested} style={darkStyles} />}
			</React.Fragment>}
			header="Parameters"
		/>
	</DataTable>
}
